import React from "react";
import "../styles/Header.css";

const Header = ({ title, onBack }) => {
    return (
        <header className="header">
            {onBack ? (
                <p className="back-button" onClick={onBack}>
                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.54995 9.99999L10.9 17.35C11.15 17.6 11.2708 17.8917 11.2625 18.225C11.2541 18.5583 11.125 18.85 10.875 19.1C10.625 19.35 10.3333 19.475 9.99995 19.475C9.66662 19.475 9.37495 19.35 9.12495 19.1L1.42495 11.425C1.22495 11.225 1.07495 11 0.974951 10.75C0.874951 10.5 0.824951 10.25 0.824951 9.99999C0.824951 9.74999 0.874951 9.49999 0.974951 9.24999C1.07495 8.99999 1.22495 8.77499 1.42495 8.57499L9.12495 0.874988C9.37495 0.624988 9.67078 0.504155 10.0125 0.512488C10.3541 0.520822 10.65 0.649988 10.9 0.899988C11.15 1.14999 11.275 1.44165 11.275 1.77499C11.275 2.10832 11.15 2.39999 10.9 2.64999L3.54995 9.99999Z" fill="white" />
                    </svg>
                </p>
            ) : (
                <div className="placeholder"></div>
            )}
            <h1 className="header-title">{title}</h1>
            <div className="placeholder"></div>
        </header>
    );
};

export default Header;