import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AddScreen from './screens/AddScreen';
import HelpScreen from './screens/HelpScreen';
import Home from './screens/Home';
import './App.css';

function App() {
  useEffect(() => {
    const loadGoogleMapsScript = (callback) => {
      const existingScript = document.getElementById('googleMaps');
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&region=KZ&language=kk&libraries=places`;
        script.id = 'googleMaps';
        document.body.appendChild(script);

        script.onload = () => {
          if (callback) callback();
        };
      } else if (callback) {
        callback();
      }
    };

    loadGoogleMapsScript();
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/add' element={<AddScreen />} />
        <Route path='/help' element={<HelpScreen />} />
        <Route path='*' element={<Home/>} />
      </Routes>
    </>
  );
}

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;