import React, { useState } from 'react';
import Lottie from 'lottie-react';
import regionImages from '../items/regionImages';
import '../styles/MapScroller.css';
import anim_birds from '../assets/anim/birds.json';
import cloud_image from '../assets/images/cloud.png';

import BottomMenu from './BottomMenu';

function MapScroller({ regions, clickedRegion, handleRegionClick, regionNamesList, handleCardClick, markerRef, expandedIndex }) {
    const [activeTab, setActiveTab] = useState('main');
    return (
        <div className={`map-scroller ${clickedRegion ? 'blurred' : ''}`}>
            <Lottie
                animationData={anim_birds}
                className="birds-animation"
                loop
                autoplay
            />
            <div className='clouds_content'>
                <img className='cloud_1' src={cloud_image} alt='cloud' />
                <img className='cloud_2' src={cloud_image} alt='cloud' />
            </div>
            {regions.map((region, index) => (
                <img
                    key={region.id}
                    src={regionImages[index]}
                    alt={`Region ${index + 1}`}
                    className={`region region${index + 1} ${index === 0 ? 'rounded' : ''} ${clickedRegion === index + 1 ? 'clicked zoomed' : ''} ${clickedRegion && clickedRegion !== index + 1 ? 'blur' : ''}`}
                    onClick={() => handleRegionClick(index + 1)}
                />
            ))}

            {clickedRegion && (
                <div className="marker" ref={markerRef}>
                    <div className="cards-container"> {/* ✅ Changed <button> to <div> */}
                        {regionNamesList.map((item, idx) => (
                            <div
                                className={`card ${expandedIndex === idx ? 'expanded' : ''}`}
                                key={idx}
                                onClick={() => handleCardClick(item.name, idx)}
                            >
                                <img
                                    src={item.images?.[0]}
                                    alt={item.name}
                                    className={`card-image ${expandedIndex === idx ? 'expanded-image' : ''}`}
                                />
                                <div className='card_text_content'>
                                    <p className='name'>{item.name}</p>
                                    <p className="description">
                                        {expandedIndex === idx
                                            ? item.description
                                            : (item.description ? (item.description.length > 25 ? `${item.description.substring(0, 25)}...` : item.description) : 'Сипаттама табылмады')
                                        }
                                    </p>
                                </div>

                                {expandedIndex !== null && (
                                    <BottomMenu activeTab={activeTab} setActiveTab={setActiveTab} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default MapScroller;