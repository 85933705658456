import { collection, getDocs } from 'firebase/firestore';
import regionNames from '../items/regionNames';
import { db } from '../config';

export const handleRegionClick = async (regionNumber, clickedRegion, setClickedRegion, setRegionNamesList, setHeaderText, setError) => {
    try {
        if (clickedRegion === regionNumber) {
            setClickedRegion(null);
            setRegionNamesList([]);
            setHeaderText('КИЕЛІ ҚАЗАҚСТАН');
        } else {
            setClickedRegion(regionNumber);
            const regionCollection = collection(db, regionNames[regionNumber - 1]);
            const regionSnapshot = await getDocs(regionCollection);
            const data = regionSnapshot.docs.map(doc => doc.data());
            setRegionNamesList(data);
            setHeaderText(regionNames[regionNumber - 1]);
        }
    } catch (err) {
        setError('Аймақ атауларын алу сәтсіз аяқталмады');
    }
};