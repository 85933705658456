import React, { useEffect, useState } from "react";
import backgroundAudio from "../audio/background.wav";
import nightBackgroundAudio from "../audio/night.wav";

function BackgroundAudio({ isNight }) {
    const [audioSrc, setAudioSrc] = useState(isNight ? nightBackgroundAudio : backgroundAudio);

    useEffect(() => {
        const audio = document.getElementById("audio");
        const playAudio = () => {
            audio.play().catch(error => console.error("Autoplay error:", error));
        };
        document.addEventListener("click", playAudio, { once: true });

        return () => document.removeEventListener("click", playAudio);
    }, []);

    useEffect(() => {
        setAudioSrc(isNight ? nightBackgroundAudio : backgroundAudio);
        const audio = document.getElementById("audio");
        if (audio) {
            audio.src = isNight ? nightBackgroundAudio : backgroundAudio;
            audio.load();
            audio.play().catch(error => console.error("Audio switch error:", error));
        }
    }, [isNight]);

    return (
        <audio id="audio" loop>
            <source src={audioSrc} type="audio/wav" />
        </audio>
    );
}

export default BackgroundAudio;