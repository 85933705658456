import React, { useState, useCallback } from 'react';
import { db, storage } from '../config';
import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DangerModal from './DangerModal';
import AddTaxiForm from './AddTaxiForm';
import '../styles/Add.css';
import { useSwipeable } from 'react-swipeable';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Header from '../components/Header';

const theme = createTheme({
    palette: {
        primary: {
            main: '#E68F5A',
        }
    },
});

const containerStyle = {
    width: "100%",
    height: "400px",
};

const defaultCenter = {
    lat: 43.222,
    lng: 76.851,
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AddScreen = () => {
    const [activeTab, setActiveTab] = useState('place');
    const [formData, setFormData] = useState({
        fio: '',
        number: '',
        name: '',
        description: '',
        city: '',
        type: '',
        point: '',
        zertteuler: [''],
        aniz: [{ atauy: '', aniz: '', audio: null }],
    });
    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const imageUrls = await Promise.all(
                images.map(async (image) => {
                    const imageRef = ref(storage, `images/${image.name}`);
                    await uploadBytes(imageRef, image);
                    return getDownloadURL(imageRef);
                })
            );

            const audioUrls = await Promise.all(
                formData.aniz.map(async (aniz, index) => {
                    if (aniz.audio) {
                        const audioRef = ref(storage, `audio/${aniz.audio.name}`);
                        await uploadBytes(audioRef, aniz.audio);
                        return getDownloadURL(audioRef);
                    }
                    return null;
                })
            );

            const dataToSave = {
                fio: formData.fio || null,
                number: formData.number || null,
                name: formData.name || null,
                description: formData.description || null,
                city: formData.city || null,
                type: formData.type || null,
                point: formData.point || null,
                zertteuler: formData.zertteuler || null,
                aniz: formData.aniz.map((aniz, index) => ({
                    ...aniz,
                    audioUrl: audioUrls[index] || null,
                })),
                images: imageUrls || null,
            };

            await addDoc(collection(db, 'places'), dataToSave);

            setFormData({
                fio: '',
                number: '',
                name: '',
                description: '',
                city: '',
                type: '',
                point: '',
                zertteuler: [''],
                aniz: [{ atauy: '', aniz: '', audio: null }],
            });
            setImages([]);
            setModalMessage('Деректер сәтті қосылды!');
            setShowModal(true);
        } catch (error) {
            console.error('Құжат қосу кезіндегі қате: ', error);
            setModalMessage('Деректерді қосу кезінде қате кетті, қайтадан көріңіз.');
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalMessage('');
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setActiveTab('taxi'),
        onSwipedRight: () => setActiveTab('place'),
    });

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const [markerPosition, setMarkerPosition] = useState(null);

    // Handle map click
    const handleMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        console.log("Selected Coordinates:", lat, lng);

        setMarkerPosition({ lat, lng });
        setFormData((prev) => ({
            ...prev,
            point: `${lat}, ${lng}`,
        }));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <div {...swipeHandlers}>
                <Header title={"Киелі орын қосу"} onBack={"/"} />
                {activeTab === 'place' && (
                    <form className="container_add" id="addDataForm" onSubmit={handleSubmit}>
                        <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 0, width: '-webkit-fill-available' } }}
                            noValidate
                            autoComplete="off"
                            className='input_content'
                        >
                            <TextField id="outlined-basic" label="Аты жөніңіз" variant="outlined" color='warning' />
                            <TextField id="outlined-basic" label="Байланыс нөміріңіз" variant="outlined" color='warning' type='number' />
                        </Box>
                        <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 0, width: '-webkit-fill-available' } }}
                            noValidate
                            autoComplete="off"
                            className='input_content'
                        >
                            <TextField id="outlined-basic" label="Орын атауы" variant="outlined" color='warning' />
                            <TextField id="outlined-basic " label="Сипаттама" variant="outlined" color='warning' multiline={true} />
                        </Box>
                        <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 0, width: '-webkit-fill-available' } }}
                            noValidate
                            autoComplete="off"
                            className='input_content'
                        >
                            {isLoaded && (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={defaultCenter}
                                    zoom={10}
                                    onClick={handleMapClick}
                                >
                                    {markerPosition && <Marker position={markerPosition} />}
                                </GoogleMap>
                            )}
                            <TextField id="outlined-basic" label="Координаттар" variant="outlined" color='warning' value={formData.point} onChange={handleInputChange} />
                            <TextField id="outlined-basic " label="Аңыз" variant="outlined" color='warning' multiline={true} />
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                color='warning'
                            >
                                Суреттер жүктеу
                                <VisuallyHiddenInput
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => console.log(event.target.files)}
                                    multiple
                                />
                            </Button>
                        </Box>
                        <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 0, width: '-webkit-fill-available' } }}
                            noValidate
                            autoComplete="off"
                            className='input_content'
                        >
                            <Button className='add_button' variant="contained" color='warning'>Жіберу</Button>
                        </Box>
                    </form>
                )}
                {activeTab === 'taxi' && <AddTaxiForm />}
                <DangerModal show={showModal} message={modalMessage} onClose={handleCloseModal} />
            </div>
        </ThemeProvider>
    );
};

export default AddScreen;