import React, { useState, useEffect, useRef, useCallback } from 'react';
import DangerMessage from '../components/DangerMessage';
import MapScroller from '../components/MapScroller';
import { fetchRegions } from '../functions/fetchRegions';
import { handleRegionClick } from '../functions/handleRegionClick';
import { useNavigate } from 'react-router-dom';
import '../styles/Home.css';
import Header from '../components/Header';
import BackgroundAudio from '../functions/BackgroundAudio';

function Home() {
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [clickedRegion, setClickedRegion] = useState(null);
  const [regionNamesList, setRegionNamesList] = useState([]);
  const [headerText, setHeaderText] = useState('КИЕЛІ ҚАЗАҚСТАН');
  const [isLoading, setLoading] = useState(true);
  const [dangerMessage, setDangerMessage] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(null);
  const markerRef = useRef(null);

  useEffect(() => {
    fetchRegions(setRegions, setLoading);
  }, []);

  const handleBack = () => {
    setExpandedIndex(null);
    setHeaderText("КИЕЛІ ҚАЗАҚСТАН");
  };

  const handleCardClick = (name, index) => {
    setExpandedIndex(index);
    setClickedRegion(name);
  };

  return (
    <div className="home">
      <BackgroundAudio />
      <Header
        title={expandedIndex !== null ? regionNamesList[expandedIndex]?.name || headerText : headerText}
        onBack={expandedIndex !== null ? handleBack : null}
      />
      <MapScroller
        regions={regions}
        clickedRegion={clickedRegion}
        handleRegionClick={(regionNumber) => handleRegionClick(
          regionNumber, clickedRegion, setClickedRegion, setRegionNamesList, setHeaderText
        )}
        regionNamesList={regionNamesList}
        handleCardClick={handleCardClick}
        markerRef={markerRef}
        expandedIndex={expandedIndex}
      />
      {dangerMessage && (
        <DangerMessage
          message={dangerMessage}
          onClose={() => setDangerMessage('')}
        />
      )}

      {isLoading && <div>Жүктелуде...</div>}
    </div>
  );
}

export default Home;