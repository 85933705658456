import React from "react";
import home_icon from "../assets/icons/home.svg";
import info_icon from "../assets/icons/info.svg";
import route_icon from "../assets/icons/route.svg";

import '../styles/BottomMenu.css';

export default function BottomMenu({ activeTab, setActiveTab }) {
  return (
    <div className="bottom-menu">
      {/* Info Button */}
      <button
        onClick={() => setActiveTab("info")}
        className={`menu-btn ${activeTab === "info" ? "active" : ""}`}
      >
        <img src={info_icon} alt="Info" className="menu-icon" />
      </button>

      {/* Home Button (Centered) */}
      <button
        onClick={() => setActiveTab("home")}
        className="menu-btn"
      >
        <img src={home_icon} alt="Home" className="menu-icon" />
      </button>

      {/* Route Button */}
      <button
        onClick={() => setActiveTab("route")}
        className={`menu-btn ${activeTab === "route" ? "active" : ""}`}
      >
        <img src={route_icon} alt="Route" className="menu-icon" />
      </button>
    </div>
  );
}